<template>
  <div class="own-container">
    <div class="bread">
      个人中心 /
      <span>个人信息</span>
    </div>
    <el-row class="form-body">
      <h5>个人信息</h5>
      <el-form
          ref="userForm"
          :model="formData"
          label-width="80px"
          :label-position="labelPosition"
          :rules="ruleUser"
          class="clearfix"
      >
        <div class="changeHead fl">
          <!--          <img :src="formData.headImg" />-->
          <ImgUpload
              ref="upload"
              img-name="头像"
              @input="getImg1"
              :value="this.formData.avatar"
          />
<!--          <p>重新上传</p>-->
        </div>
        <div class="fl">
          <el-form-item prop="realName" label="真实姓名" :rules="ruleUser">
            <el-input v-model="formData.realName" type="text" placeholder="请输入真实姓名"></el-input>
          </el-form-item>
          <el-form-item prop="carNum" label="身份证号">
            <el-input v-model="formData.carNum" type="text" placeholder="请输入身份证号"></el-input>
          </el-form-item>
          <el-form-item prop="workAddressStr" label="工作地点">
<!--            <el-input v-model="formData.workAddressStr" type="text" placeholder="请输入工作地点"></el-input>-->
<!--            <span class="demonstration">默认 click 触发子菜单</span>-->
            <el-cascader
                v-model="formData.workAddressStr"
                :options="areas"
                label="text"
                @change="handleChange"></el-cascader>
          </el-form-item>
        </div>
      </el-form>
      <div class="line">
        <el-button type="default" size="small">取消</el-button>
        <el-button type="primary" size="small" @click="handleUpdate()">保存</el-button>
      </div>
    </el-row>
  </div>
</template>

<script>
import ImgUpload from "./components/ImgUpload";
import {updateDetail, getUserDetail} from '@/utils/api/own'
import {getTree} from '@/utils/api/dict'
import { getStore } from "@/utils/storage";

export default {
  name: "info",
  components: {
    ImgUpload
  },
  data() {
    const verifyID = (idcode)=>{
      // 加权因子
      var weight_factor = [7,9,10,5,8,4,2,1,6,3,7,9,10,5,8,4,2];
      // 校验码
      var check_code = ['1', '0', 'X' , '9', '8', '7', '6', '5', '4', '3', '2'];

      var code = idcode + "";
      var last = idcode[17];//最后一位

      var seventeen = code.substring(0,17);

      // ISO 7064:1983.MOD 11-2
      // 判断最后一位校验码是否正确
      var arr = seventeen.split("");
      var len = arr.length;
      var num = 0;
      for(var i = 0; i < len; i++){
        num = num + arr[i] * weight_factor[i];
      }
      // 获取余数
      var resisue = num%11;
      var last_no = check_code[resisue];
      var idcard_patter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;

      // 判断格式是否正确
      var format = idcard_patter.test(idcode);

      // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
      return last === last_no & format ? true : false;
    }
    var checkIdCard= (rule, value, callback)=>{

      if (!verifyID(value)){
        callback(new Error('请输入正确的身份证'));
      }else{
        callback();
      }
    }
    return {
      labelPosition: "left",
      staticUrl: getStore("imgBase"),
      formData: {
        avatar: getStore("imgBase") + "/dict/defaultAvatar.png",
        realName: "",
        carNum: "",
        workAddressStr: "",
        workAddress: ""
      },
      areas: [],
      ruleUser: {
        avatar: [{required: true, message: "请上传头像", trigger: "blur"}],
        realName: [
          {required: true, message: "请输入真实姓名", trigger: "blur"}
        ],
        carNum: [
          {required: true, message: "请输入身份证号", trigger: "blur"},
          { validator: checkIdCard, trigger: ['blur','change'] }
        ],
        workAddressStr: [
          {required: true, message: "请输入工作地址", trigger: "blur"}
        ]
      },
    };
  },
  created() {
    // console.log(this.$store.state.userInfo)
    this.getUserDetail()
  },
  methods: {
    handleChange(value){
      this.formData.workAddress = value[2]
    },

    // 接受头像地址i
    getImg1(value) {
      this.formData.avatar = value;
    },
    // 修改个人信息
    handleUpdate() {
      this.$refs['userForm'].validate(async(valid)=> {
        if(valid){
          this.formData.cmd = 1
          let data = this.formData
          delete  data.workAddressStr
          data.avatar = data.avatar.replace(getStore("imgBase"),'')
          updateDetail(data).then(res => {
            if(res.code === 0 ){
              this.$store.dispatch('getUserStoreInfo')
              this.getUserDetail()
            }
          })
        }
      })

    },
    // 获取用户信息
    getUserDetail() {
      getUserDetail().then(res => {
        if(res.code === 0){
          let provience = res.data.workAddress[0]+res.data.workAddress[1]
          let city = provience + res.data.workAddress[2]+res.data.workAddress[3]
          this.formData.workAddressStr = [provience,city,res.data.workAddress]
          this.formData.avatar =  res.data.avatar==""?this.formData.avatar:res.data.avatar
          this.formData.carNum = res.data.carNum
          this.formData.realName = res.data.realName
          this.formData.workAddress = res.data.workAddress
          // this.formAdd  = Object.assign({},this.formAdd, row)
        }
      })
    },
    // 获取地区列表
    getTree(){
      getTree().then(res => {
        if(res.code === 0) {
          res.data.children.map(item => {
            item.label = item.text
            item.value = item.id
            item.children.map(value => {
              value.label = value.text
              value.value = value.id
              value.children.map(v => {
                v.label=v.text
                v.value=v.id
                delete v.children
              })
            })
          })
          this.areas = res.data.children
        }
      })
    }
  },
  mounted() {

    this.getTree()
  }
};
</script>

<style  lang="less">
.form-body {
  background-color: #ffffff;
  padding: 30px 30px 60px 30px;

  h5 {
    height: 22px;
    font-size: 16px;
    font-weight: 800;
    color: #333333;
    line-height: 22px;
    margin-bottom: 30px;
  }

  .changeHead {
    width: 150px;
    height: 120px;
    border-radius: 2px;
    position: relative;
    margin-right: 60px;

    &:hover {
      cursor: pointer;
    }

    img {
      width: inherit;
      height: inherit;
    }

    p {
      position: absolute;
      width: 150px;
      height: 25px;
      background: rgba(0, 0, 0, 0.7);
      border-radius: 0px 0px 2px 2px;
      text-align: center;
      color: #ffffff;
      bottom: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
    }
  }

  .el-form-item__label {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
  }

  .el-form-item__content {
    line-height: 42px;

    .el-input__inner {
      width: 360px;
      height: 42px;
      line-height: 42px;
      background: #fafafa;
      border-radius: 2px;
      border: none;
    }
  }

  .line {
    text-align: right;
    border-top: 1px solid #f1f1f1;
    padding-top: 30px;
  }
}
</style>

