/**
 * @notice 字典类
 * @author hhq
 */
import http from "../http"
//通用配置查询
export const getDict =(params)=>{
    return http.postDictDetail('/ad/app/dict/detail',params)
}
//责任人列表
//负责人
export const getResponsible =(params)=>{
    return http.postRequest('/app/dict/getResponsible',params)
}
//品牌列表
export const getCarModels =(params)=>{
    return http.postRequest('/app/tCarBrand/getCarModeSelectVo',params)
}
//获取图片前缀
export const getImgUrl =(params)=>{
    return http.getRequest('/ios/app/user/host',params)
}

//省市区地区编码
export const getTree =(params)=>{
    return http.postRequest('/ad/app/tool/getTree',params)
}
//获取车辆列表
export const getCarList = (params)=>{
    return http.getRequest('/car/selectCarList',params)
}
